.faq_button {
  padding-block: 16px;
  padding-inline: 32px;
  color: #39848a;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
}

.faqTitle {
  color: white;
  text-align: center !important;
  font-family: "Open Sans";
  font-size: 40px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 48px !important;
}

.faqSubtitle {
  color: white;
  text-align: center;
  font-family: "Open Sans";
  width: 530px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400px;
  line-height: 24px !important;
}

.faqBoxSubtitle {
  color: white;
  display: flex;
  justify-content: center;
  padding-block: 24px;
}

/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .faqTitle {
    color: white;
    text-align: center !important;
    font-family: "Open Sans";
    font-size: 32px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 48px !important;
  }

  .faqSubtitle {
    color: white;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
/* Mobile: */
@media screen and (max-width: 743px) {
  .faqTitle {
    color: white;
    text-align: center !important;
    font-family: "Open Sans";
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  .faqSubtitle {
    color: white;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
/* Mobile: */
@media screen and (max-width: 385px) {
  .faqTitle {
    color: black;
    text-align: center !important;
    font-family: "Open Sans";
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  .faqSubtitle {
    color: black;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
